import * as React from "react";
import { useRouter } from "next/router";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress"; // Import Circular Loader
import formatKeyword from "@/features/jobSearch/hooks/formatKeyword";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  categories,
  locationData1,
  locationData2,
  designationData1,
  designationData2,
} from "@/components/UI/FooterLink/data";

function FooterLink() {
  const router = useRouter();
  const [designations, setDesignation] = React.useState(designationData1.data);
  const [designationSet2, setDesignationSet2] = React.useState(
    designationData2.data
  );
  const [isLoading, setIsLoading] = React.useState(false);

  // Function to update designations when location changes
  const updateDesignations = () => {
    const currentLocation = localStorage?.getItem("currentLocation");
    if (currentLocation) {
      setDesignationSet2(
        designationData2?.data.map((item) => ({
          link: `${item?.link}${formatKeyword(` in ${currentLocation}`)}`,
          title: item.title,
        }))
      );
      setDesignation(
        designationData1?.data.map((item) => ({
          link: `${item?.link}${formatKeyword(` in ${currentLocation}`)}`,
          title: item.title,
        }))
      );
    }
  };

  React.useEffect(() => {
    // Initial load
    updateDesignations();

    // Listen for route changes and update designations
    const handleRouteChange = () => {
      updateDesignations();
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    // Clean up the event listener on component unmount
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  const sliderSettingsFast = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 3500,
    autoplaySpeed: 3500,
    cssEase: "linear",
    arrows: false,
    pauseOnHover: true,
    variableWidth: true,
    swipeToSlide: true,
  };

  const sliderSettingsSlow = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    arrows: false,
    pauseOnHover: true,
    variableWidth: true,
    swipeToSlide: true,
  };

  const duplicateData = (data) => [...data, ...data];

  const handleNavigation = async (link) => {
    setIsLoading(true); // Start loader
    console.log("Navigating to:", link); // Debugging: Log link
    try {
      await router.push(link); // Wait for navigation
    } catch (error) {
      console.error("Navigation error:", error);
    } finally {
      setIsLoading(false); // Stop loader after navigation attempt
    }
  };

  return (
    <Box
      sx={{
        p: "48px 0px",
        textAlign: "center",
        borderTop: "1px solid #D7DDE5",
        "& .MuiTypography-root": {
          color: "#1C1B1B",
          p: "8px 16px",
          border: "1px solid #D7DDE5",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "30px",
          fontSize: {md:"14px", xs:"12px"},
          fontWeight: 400,
          background: "#FFFFFF",
          ":hover": {
            border: "1px solid #2a5798",
            background: "#F1F5FA",
          },
        },
        "& .slick-slide": {
          padding: "0 8px",
        },
        "& .slick-list": {
          overflow: "hidden",
        },
        "& .slick-track": {
          display: "flex !important",
        },
      }}
    >
      {isLoading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "white",
            zIndex: 9999,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <Box sx={{ padding: "0 16px" }}>
        <Box sx={{ fontSize: {md:"24px", xs:"20px"}, fontWeight: 600, mb: "16px" }}>
          {categories?.slug}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          {categories?.data?.map((item, i) => (
            <React.Fragment key={i}>
              <Box sx={{margin: "4px"}}>
              <a
                href={item.link} // For SEO purpose
                onClick={(e) => {
                  e.preventDefault(); // Prevent default navigation if using onClick
                  handleNavigation(item.link);
                }}
                style={{
                  color: "#1C1B1B",
                }}
              >
                <Typography fontWeight={400}>
                  {item.title}
                </Typography>
              </a>
            </Box>
            </React.Fragment>
          ))}
        </Box>

        <Box sx={{ fontSize: {md:"24px", xs:"20px"}, fontWeight: 600, mb: "16px", mt: "20px" }}>
          {locationData1?.slug}
        </Box>
        <Slider {...sliderSettingsFast}>
          {duplicateData(locationData1?.data).map((item, index) => (
            <Box
              key={index}
            >
              <a
                href={item.link} // For SEO purpose
                onClick={(e) => {
                  e.preventDefault(); // Prevent default navigation if using onClick
                  handleNavigation(item.link);
                }}
                style={{
                  color: "#1C1B1B",
                }}
              >
                <Typography fontWeight={400} mb={2}>
                  {item.title}
                </Typography>
              </a>
            </Box>
          ))}
        </Slider>
        <Slider {...sliderSettingsSlow}>
          {duplicateData(locationData2?.data).map((item, index) => (
            <Box
              key={index}
            >
              <a
                href={item.link} // For SEO purpose
                onClick={(e) => {
                  e.preventDefault(); // Prevent default navigation if using onClick
                  handleNavigation(item.link);
                }}
                style={{
                  color: "#1C1B1B",
                }}
              >
                <Typography fontWeight={400} mb={2}>
                  {item.title}
                </Typography>
              </a>
            </Box>
          ))}
        </Slider>

        <Box sx={{ fontSize: {md:"24px", xs:"20px"}, fontWeight: 600, mb: "16px", mt: "20px" }}>
          {designationData1?.slug}
        </Box>
        <Slider {...sliderSettingsFast}>
          {duplicateData(designationData1?.data).map((item, index) => (
            <Box
              key={index}
            >
              <a
                href={item.link} // For SEO purpose
                onClick={(e) => {
                  e.preventDefault(); // Prevent default navigation if using onClick
                  handleNavigation(item.link);
                }}
                style={{
                  color: "#1C1B1B",
                }}
              >
                <Typography fontWeight={400} mb={2}>
                  {item.title}
                </Typography>
              </a>
            </Box>
          ))}
        </Slider>
        <Slider {...sliderSettingsSlow}>
          {duplicateData(designationData2?.data).map((item, index) => (
            <Box
              key={index}
            >
              <a
                href={item.link} // For SEO purpose
                onClick={(e) => {
                  e.preventDefault(); // Prevent default navigation if using onClick
                  handleNavigation(item.link);
                }}
                style={{
                  color: "#1C1B1B",
                }}
              >
                <Typography fontWeight={400} mb={2}>
                  {item.title}
                </Typography>
              </a>
            </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  );
}

export default FooterLink;
